.contact_us {
  min-height: calc(100vh - 260px );
  text-align: center;
}

.contact_us li a{
  font-family: Antagonist;
  padding-left: 20px;
}

.social {
  margin: 0 auto;
}

.social h2{
  font-family: Antagonist;
  font-size: 40px;
}

.social ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.social li {
  padding: 40px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social li img{
  max-width: 80px;
  max-height: 80px;
}

.social a {
  text-decoration: none;
  color: black;
}