.policy{
  max-width: 60%;
  margin: 0 auto;
  text-align: justify;
}

.policy p{
  font-size: 17px;
}

@media (max-width: 768px) {
  .policy{
    max-width: 90%;
  }
}