.about_container{
  background-color: #2d65b0;
  padding-top: 30px;
  color: white;
}

.about{
  margin: 0 auto;
  max-width: 1400px;
  padding-bottom: 30px;
}

.about h2{
  font-size: 40px;
  font-family: Antagonist;
}

.about_body{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about p{
  max-width: 50%;
  text-align: left;
  text-align: justify;
}

.about img{
  max-width: 30%;
}

@media (max-width: 768px) {

  .about{
    max-width: 90%;
    padding-bottom: 30px;
  }

  .about h2{
    font-size: 30px;
  }

  .about_body{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  .about p{
    max-width: 90%;
    font-size: 16px;
  }
  
  .about img{
    max-width: 60%;
  }

}