.logos_container{
  background: #dcdcdc;
}

.logos_container h2{
  font-family: Antagonist;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 10px;
}

.logos{
  max-width: 70%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.logos img{
  max-width: 50%;
  margin: 20px auto;
}

@media (min-width: 768px) and (max-width: 1440px) {

  .logos{
    margin: 0 auto;
    max-width: 80%;
  }

  .logos img{
    max-width: 80%;
    margin: 0 auto;
  }

}


@media (max-width: 768px) {

  .logos{
    max-width: 90%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .logos img{
    max-width: 90%;
    margin: 0 auto;
  }

}