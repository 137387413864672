.App {
  text-align: center;
  margin: 0;
}

@font-face {
  font-family: Antagonist;
  src: url(../public/Antagonist.ttf);
}

.Antagonist {
  font-family: Antagonist;
}