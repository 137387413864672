.contact{
  margin: 0 auto;
  padding-bottom: 60px;
}

.contact h2{
  font-size: 40px;
  padding-top: 60px;
  padding-bottom: 60px;
  margin: 0;
  font-family: Antagonist;
}

.map_container{
  display: flex;
  max-width: 1400px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-around;
}

.map_container iframe{
  border: 0; 
  border-style: none;
  width: 650px;
  height: 450px;
}

.info_container{
  display: block;
}

.info_container a{
  text-decoration: none;
  color: #7fa62a;
}

.info_container button{
  background-color: #9dc446;
  border: none;
  border-radius: 10px;
  padding: 15px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin-right: 30px;
  cursor: pointer;
}

@media (max-width: 768px) {

  .map_container{
    display: fled;
    flex-direction: column-reverse;
    max-width: 70%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-around;
  }
  
  .map_container iframe{
    border: 0; 
    border-style: none;
    max-width: 100%;
    max-height: 250px;
  }

  .info_container{
    display: block;
  }
  
  .info_container button{
    padding: 15px;
    color: white;
    font-size: 20px;
    margin-right: 0px;
    margin-top: 10px;
  }

}