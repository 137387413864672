.info{
  background: linear-gradient(45deg, #8edefc 0%, rgba(15,197,255,100) 100%);
  color: white;
  padding-bottom: 60px;
}

.info h2{
  font-size: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.sections{
  text-align: left;
  margin: 0 auto;
  max-width: 70%;
}

.section{
  text-align: justify;
}

@media (max-width: 768px) {

  .section p{
    font-size: 16px;
  }

}