.customer{
  min-height: calc(100vh - 226px);
  display: flex;
  flex-direction: column;
}

.headline_container{
  background-color: #20518d;
}

.headline{
  color: white;
  display: flex;
  max-width: 1400px;
  margin: 50px auto;
}

.headline img{
  max-width: 300px;
  margin: 0 auto;
}

.headline_text {
  text-align: justify;
  max-width: 50%;
  margin: 0 auto
}

.headline_text h2 {
  font-size: 50px;
}

.headline_text p {
  font-size: 17px;
}

.products_container {
  flex-grow: 1;
}

.products_grid {
  max-width: 80%;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

.products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 55px;
  grid-row-gap: 50px;
}

@media (max-width: 768px) {

  .customer{
    width: 100%;
  }

  .headline{
    display: flex;
    flex-direction: column-reverse;
    max-width: 90%;
    margin: 0px auto;
    padding-bottom: 20px;
  }

  .headline img{
    max-width: 50%;
    margin: 0 auto;
  }
  
  .headline_text {
    padding-bottom: 20px;
    max-width: 90%;
  }

  .headline_text h2 {
    font-size: 40px;
  }
  
  .headline_text p {
    font-size: 17px;
  }
  
  .products_grid {
    max-width: 90%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .products {
    display: block;
  }

}

@media (min-width: 769px) and (max-width: 1440px) {

  .products_grid {
    max-width: 90%;
  }

  .products {
    grid-template-columns: repeat(3, 1fr);
  }

}