.slider{
  max-width: 1400px;
  margin: 0 auto;
}

.cards{
  margin: 50px auto;
  max-width: 1400px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.card {
  width: 300px;
  font-family: Antagonist;
  box-shadow:
    0 0 0 5px #a1c547,
    0 0 0 10px #20518d;
  padding: 10px 10px;
  transition: transform .2s ease-in-out;
}

.card:hover{
  transform: scale(1.05);
}

.cards a{
  text-decoration: underline;
  color: #20518d;
}

.card p{
  font-size: 30px;
  font-weight: 400;
}

@media (max-width: 768px) {

  .slider{
    display: none;
  }

  .cards{
    max-width: 90%;
    display: block;
    justify-content: center;
    margin: 50px auto;
  }
  
  .card{
    margin-bottom: 50px;
    margin: 50px auto;
    max-width: 90%;
    padding: 30px 0px;
  }
  
  .card a{
    
  }
  
  .card p{
    font-size: 25px;
    margin: 0;
  }
  
}

@media (min-width:769px) and (max-width: 1302px) {

  .title{
    max-width: 90%;
  }

   .title h1{
    font-size: 140px;
    margin-top: 10px;
    margin-bottom: 100px;
  }
}