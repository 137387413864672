footer {
  padding: 20px 0;
  background-color: #9dc446;
  font-weight: 400;
}

footer ul {
  margin-top: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  font-size: 12px;
  line-height: 1.6;
  margin-bottom: 0;
}

footer ul a {
  color: inherit;
  text-decoration: none;
  opacity: 1;
}

footer ul li {
  display: inline-block;
  padding: 0 10px;
}

footer p {
  margin-top: 10px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
}

footer a{
  text-decoration: none;
  color: black;
  font-weight: 500;
}