.partner_container{
  max-width: 50%;
  margin: 0 auto;
}

.partner{
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.partner img{
  margin-right: 150px;
  max-width: 20%;
}

.partner h2{
  text-align: left;
}

.partner p {
  text-align: justify;
}

@media (min-width: 769px) and (max-width: 1440px) {
  .partner_container{
    max-width: 70%;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {

  .partner_container{
    max-width: 90%;
    margin: 0 auto;
  }
  
  .partner{
    display: block;
  }

  .partner img{
    margin-right: 30px;
    max-width: 80%;
  }
  
  .partner h2{
    text-align: left;
  }
  
  .partner p {
    text-align: justify;
  }

}