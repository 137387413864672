@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  background-color: #fcfcfc;
  font-size: 20px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  height: 60px;
  position: relative;
}

.logo a{
  text-decoration: none;
  color: #20518d;
  font-family: Antagonist;
}

.menu-icon {
  display: none;
}

.nav-elements {
  
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #000000;
  font-weight: 600;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media (max-width: 768px) {

  .logo a{
  font-size: 16px;
  }

  .nav-elements ul li:not(:last-child) {
    margin-right: 15px;
  }

}

@media (max-width: 600px) {

  .nav-elements ul li:not(:last-child) {
    margin: 0 auto;
  }

  .flex{
    display: flex;
    align-items: center;
    margin: 0 auto;
    justify-content: space-between;
    max-width: 90%;
  }

  .container {
    max-width: 100%;
    padding: 0 0;
    display: block;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    float: left;
    background: #e6e6e6;
    width: 0px;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 100%;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0;
    list-style-type: none;
    padding-bottom: 20px;
  }

  .nav-elements ul li {
    padding-top: 20px;
    padding-bottom: 10px;
  }
}