.product {
  background-color: white;
  transition: transform .2s ease-in-out;
  box-shadow:
    0 0 0 5px #a1c547,
    0 0 0 10px #20518d;
}

.product:hover{
  transform: scale(1.07);
}

.product h3{
  font-size: 24px;
  font-family: Antagonist;
  color: #20518d;
}

.product p{
  font-size: 16px;
  max-width: 90%;
  margin: 0 auto;
  text-align: justify;
  padding-bottom: 20px;
}

@media (max-width: 768px) {
  .product {
    transition: none;
    margin: 50px auto;
  }
  
  .product:hover{
    transform: none;
  }

  .product h3{
    font-size: 17px;
    padding-top: 20px;
  }
  
  .product p{
    font-size: 14px;
    max-width: 90%;
  }

}